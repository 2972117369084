const commissionFilterTitles = {
    period: '工作年度',
    month: '工作月',
    _seq: ['period', 'month']
}

/*
 *** 佣金查詢表頭
 */
const commissionColumns = {
    downloadZip: {
        label: '佣金檔',
        sortEnabled: false,
        isFilterable: false,
        isExcelHide: true,
        type: 'string',
        position: 'center',
        width: 5,
        widthPx: 45,
        isUnmovable: true,
        isUnhide: true
    },
    newPeriod: {
        label: '工作月',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        total: 'key',
        width: 10,
        widthPx: 78,
        isUnmovable: true
    },
    type: {
        label: '佣金別',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 85,
        isUnmovable: true
    },
    mTypeName: {
        label: '制度別',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 78
    },
    branch: {
        label: '營業單位',
        sortEnabled: true,
        isFilterable: true,
        position: 'center',
        width: 12,
        widthPx: 90,
        isUnmovable: true
    },
    name: {
        label: '姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100,
        isUnmovable: true
    },

    saleTotal: {
        label: '直接佣金',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 95
    },
    organizationTotal: {
        label: '組織佣金',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 95
    },
    otherTotal: {
        label: '其他加扣款',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 12,
        widthPx: 100
    },
    totalAmount: {
        label: '總佣金',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 12,
        widthPx: 100
    }
}

/*
 *** 發佣作業 開始計算佣金備註
 */

const adminCalcDescriptions = [
    '請注意：',
    '1. 系統將依序執行下方勾選資料，如有錯誤則中斷後續計算步驟',
    '2. 再次從PKS更新耗費時間約為40分鐘，請勿關掉視窗'
]

/*
 *** 轄下佣金
 */

const underlingFilterTitles = {
    startDate: '工作月(起)',
    endDate: '工作月(迄)',
    relationMode: '組織種類',
    _seq: ['startDate', 'endDate', 'relationMode']
}

const underlingColumns = {
    name: {
        label: '業務員姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'left',
        // total: 'key',
        widthPx: 110,
        width: 10,
        isUnmovable: true,
        isUnhide: true
    },
    relativeDepth: {
        label: '代數',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 50,
        width: 8
    },
    bgName: {
        label: '營業單位',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 110,
        width: 10
    },
    normalSaleTotal: {
        label: '銷售佣金',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        // total: 'sum',
        unit: '元',
        widthPx: 120,
        width: 18,
        rowNumber: 1
    },
    yearBonusSaleTotal: {
        label: '年終獎金',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        // total: 'sum',
        unit: '元',
        widthPx: 120,
        width: 18,
        rowNumber: 2
    },
    normalOrganizationTotal: {
        label: '組織佣金',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        // total: 'sum',
        unit: '元',
        widthPx: 120,
        width: 18,
        rowNumber: 1
    },
    yearBonusOrganizationTotal: {
        label: '年終組織獎金',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        // total: 'sum',
        unit: '元',
        widthPx: 120,
        width: 18,
        rowNumber: 2
    },
    //2022/11/03 累積扣款問題暫解↓
    // normalOtherTotal: {
    //     label: '其他加扣款',
    //     sortEnabled: false,
    //     isFilterable: false,
    //     type: 'number',
    //     position: 'right',
    //     // total: 'sum',
    //     unit: '元',
    //     widthPx: 120,
    //     width: 18,
    //     rowNumber: 1
    // },
    // yearBonusOtherTotal: {
    //     label: '年終其他加扣款',
    //     sortEnabled: false,
    //     isFilterable: false,
    //     type: 'number',
    //     position: 'right',
    //     // total: 'sum',
    //     unit: '元',
    //     widthPx: 120,
    //     width: 18,
    //     rowNumber: 2
    // },
    //2022/11/03 累積扣款問題暫解↑
    normalTotalAmount: {
        label: '實領總計',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        // total: 'sum',
        unit: '元',
        widthPx: 140,
        width: 20,
        rowNumber: 1,
        toolTips: {
            hasToolTips: true,
            content: { desktop: '已包含其他加扣款項', mobile: null } // <!-- 2022/11/03 累積扣款問題暫解 -->
        }
    },
    yearBonusTotalAmount: {
        label: '年終實領總計',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        // total: 'sum',
        unit: '元',
        widthPx: 140,
        width: 20,
        rowNumber: 2,
        toolTips: {
            hasToolTips: true,
            content: { desktop: '已包含其他加扣款項', mobile: null } // <!-- 2022/11/03 累積扣款問題暫解 -->
        }
    }
}

export {
    commissionFilterTitles,
    commissionColumns,
    adminCalcDescriptions,
    underlingFilterTitles,
    underlingColumns
}
